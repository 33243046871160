@import './_variables';
@import './_fonts';
@import '../../../theme-base/_components';
@import '../../_vendor_extensions';
@import './_extensions';

.p-selectbutton .p-button {
    background: white;
    border: 1px solid rgb(232, 232, 232);
    box-shadow: none;
    color: rgb(44, 44, 44);


    &.p-highlight {
        background: var(--surface-600);
        color: white;
    }
}

.p-dialog {
    .p-dialog-content {
        overflow: hidden !important;
    }
}

.p-timeline-event {
    min-height: 20px;
}

.p-timeline-event-opposite {
    display: none;
    flex: 1;
}

p-timeline,
.p-timeline {
    max-width: 1000px;
}

.progressTimestamp.embedded {
    &.hide-timestamp {
        display: block;
    }
}



@media(min-width: 992px) {
    .main-menu {
        p-menubarsub {
            width: 100%;
        }

        .p-menubar-root-list {
            width: 100%;
            display: flex;
        }

        .p-menuitem {
            a {
                &:hover {
                    color: blue
                }
            }
        }

        .p-menuitem-link-active,
        .parent-active {
            pointer-events: none;
            cursor: default;

            .p-menuitem-icon,
            .p-menuitem-text {
                color: var(--primary-color) !important;
            }

            .p-menuitem-text {
                font-weight: 700;

            }
        }

        .parent-active {
            pointer-events: all;
        }
    }

    .p-timeline-event-opposite {
        display: block;
        flex: 0;
        min-width: 150px;
        max-width: 150px;
        overflow: hidden;
    }

    .progressTimestamp.embedded {
        display: none;

        &.hide-timestamp {
            display: none;
        }
    }


}
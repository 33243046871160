$apyreBlue: #0072BC;
$apyreSkyBlue: #C8DFF6;
$apyreAppBackground: #F9F7FA;
$apyreBorder1: #DADEE3;

$colors: (
    "blue": #0072BC,
    "green": #198754,
    "yellow": #FFC107,
    "cyan": #0DCAF0,
    "pink": #D63384,
    "indigo": #6610F2,
    "teal": #20C997,
    "orange": #FD7E14,
    "bluegray": #7C8EA7,
    "purple": #6F42C1,
    "red": #DC3545,
    "primary": $primaryColor) !default;
//reused color variables
$shade000: #FFFFFF !default; //surface
$shade100: #EFF2F5 !default; //header background
$shade200: #F9F7FA !default; //hover background
$shade300: #878787 !default; //border, divider
$shade400: #CED4DA !default; //input border
$shade500: #ADB5BD !default; //unused
$shade600: #6C757D !default; //text color for the top bar
$shade700: #4C4C4D !default; //input text color //text secondary color
$shade800: #343A40 !default; //unused
$shade900: #231F20 !default; //text color
//global
$fontFamily: "Raleway",
    Helvetica,
    sans-serif !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $shade900 !default;
$textSecondaryColor: $shade700 !default;
$borderRadius: 6px !default;
$transitionDuration: .15s !default;
$formElementTransition: background-color $transitionDuration,
    border-color $transitionDuration,
    box-shadow $transitionDuration !default;
$actionIconTransition: box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid $shade300 !default;
$inlineSpacing: .5rem !default;
$disabledOpacity: .65 !default;
$maskBg: rgba(0, 0, 0, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: #DC3545 !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: $primaryLightColor !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$focusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//action icons
$actionIconWidth: 2rem !default;
$actionIconHeight: 2rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $shade600 !default;
$actionIconHoverBg: transparent !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $shade700 !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: .3rem .75rem !default;
$inputTextFontSize: 1rem !default;
$inputBg: $shade000 !default;
$inputTextColor: $shade700 !default;
$inputIconColor: $shade700 !default;
$inputBorder: 1px solid var(--surface-900) !default;
$inputHoverBorderColor: var(--surface-900) !default;
$inputFocusBorderColor: $primaryColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $shade600 !default;
$inputFilledBg: $shade100 !default;
$inputFilledHoverBg: $shade100 !default;
$inputFilledFocusBg: $shade100 !default;

//input groups
$inputGroupBg: $shade200 !default;
$inputGroupTextColor: $shade700 !default;
$inputGroupAddOnMinWidth: 2.357rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $shade000 !default;
$inputListTextColor: $shade900 !default;
$inputListBorder: $inputBorder !default;
$inputListPadding: .5rem 0 !default;
$inputListItemPadding: .5rem 1.5rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $shade900 !default;
$inputListItemHoverBg: $shade200 !default;
$inputListItemTextHoverColor: $shade900 !default;
$inputListItemFocusBg: $shade000 !default;
$inputListItemTextFocusColor: $shade900 !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor !default;
$inputListHeaderPadding: .75rem 1.5rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: $shade100 !default;
$inputListHeaderTextColor: $shade900 !default;
$inputListHeaderBorder: 1px solid $shade300 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 1px solid rgba(0, 0, 0, .15) !default;
$inputOverlayShadow: none !default;

//password
$passwordMeterBg: $shade200 !default;
$passwordWeakBg: #dc3545 !default;
$passwordMediumBg: #ffc107 !default;
$passwordStrongBg: #28a745 !default;

//button
$buttonPadding: .5rem .75rem !default;
$buttonIconOnlyWidth: 2.357rem !default;
$buttonIconOnlyPadding: .5rem 0 !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $primaryColor !default;
$buttonHoverBg: $primaryDarkColor !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: $primaryDarkColor !default;
$buttonActiveBg: $primaryDarkerColor !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: $primaryDarkerColor !default;
$raisedButtonShadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
    0 2px 2px 0 rgba(0, 0, 0, .14),
    0 1px 5px 0 rgba(0, 0, 0, .12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: .04 !default;
$textButtonActiveBgOpacity: .16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: $shade200 !default;
$plainButtonActiveBgColor: $shade300 !default;

$secondaryButtonBg: #6c757d !default;
$secondaryButtonTextColor: #ffffff !default;
$secondaryButtonBorder: 1px solid #6c757d !default;
$secondaryButtonHoverBg: #5a6268 !default;
$secondaryButtonTextHoverColor: #ffffff !default;
$secondaryButtonHoverBorderColor: #5a6268 !default;
$secondaryButtonActiveBg: #545b62 !default;
$secondaryButtonTextActiveColor: #ffffff !default;
$secondaryButtonActiveBorderColor: #4e555b !default;
$secondaryButtonFocusShadow: 0 0 0 0.2rem rgba(130, 138, 145, .5) !default;

$infoButtonBg: #17a2b8 !default;
$infoButtonTextColor: #ffffff !default;
$infoButtonBorder: 1px solid #17a2b8 !default;
$infoButtonHoverBg: #138496 !default;
$infoButtonTextHoverColor: #ffffff !default;
$infoButtonHoverBorderColor: #117a8b !default;
$infoButtonActiveBg: #138496 !default;
$infoButtonTextActiveColor: #ffffff !default;
$infoButtonActiveBorderColor: #117a8b !default;
$infoButtonFocusShadow: 0 0 0 0.2rem rgba(58, 176, 195, .5) !default;

$successButtonBg: #28a745 !default;
$successButtonTextColor: #ffffff !default;
$successButtonBorder: 1px solid #28a745 !default;
$successButtonHoverBg: #218838 !default;
$successButtonTextHoverColor: #ffffff !default;
$successButtonHoverBorderColor: #1e7e34 !default;
$successButtonActiveBg: #1e7e34 !default;
$successButtonTextActiveColor: #ffffff !default;
$successButtonActiveBorderColor: #1c7430 !default;
$successButtonFocusShadow: 0 0 0 0.2rem rgba(72, 180, 97, .5) !default;

$warningButtonBg: #ffc107 !default;
$warningButtonTextColor: #212529 !default;
$warningButtonBorder: 1px solid #ffc107 !default;
$warningButtonHoverBg: #e0a800 !default;
$warningButtonTextHoverColor: #212529 !default;
$warningButtonHoverBorderColor: #d39e00 !default;
$warningButtonActiveBg: #d39e00 !default;
$warningButtonTextActiveColor: #212529 !default;
$warningButtonActiveBorderColor: #c69500 !default;
$warningButtonFocusShadow: 0 0 0 0.2rem rgba(222, 170, 12, .5) !default;

$helpButtonBg: #6f42c1 !default;
$helpButtonTextColor: #ffffff !default;
$helpButtonBorder: 1px solid #6f42c1 !default;
$helpButtonHoverBg: #633bad !default;
$helpButtonTextHoverColor: #ffffff !default;
$helpButtonHoverBorderColor: #58349a !default;
$helpButtonActiveBg: #58349a !default;
$helpButtonTextActiveColor: #ffffff !default;
$helpButtonActiveBorderColor: #4d2e87 !default;
$helpButtonFocusShadow: 0 0 0 0.2rem #d3c6ec !default;

$dangerButtonBg: #dc3545 !default;
$dangerButtonTextColor: #ffffff !default;
$dangerButtonBorder: 1px solid #dc3545 !default;
$dangerButtonHoverBg: #c82333 !default;
$dangerButtonTextHoverColor: #ffffff !default;
$dangerButtonHoverBorderColor: #bd2130 !default;
$dangerButtonActiveBg: #bd2130 !default;
$dangerButtonTextActiveColor: #ffffff !default;
$dangerButtonActiveBorderColor: #b21f2d !default;
$dangerButtonFocusShadow: 0 0 0 0.2rem rgba(225, 83, 97, .5) !default;

$linkButtonColor: $primaryColor !default;
$linkButtonHoverColor: $primaryDarkColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth: 20px !default;
$checkboxHeight: 20px !default;
$checkboxBorder: 1px solid var(--surface-900) !default;
$checkboxIconFontSize: 14px !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryDarkerColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth: 20px !default;
$radiobuttonHeight: 20px !default;
$radiobuttonBorder: 2px solid $shade400 !default;
$radiobuttonIconSize: 12px !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryColor !default;
$radiobuttonIconActiveColor: $primaryTextColor !default;
$radiobuttonActiveHoverBg: $primaryDarkerColor !default;
$radiobuttonIconActiveHoverColor: $primaryTextColor !default;
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: #212529 !default;
$colorPickerBorder: 1px solid #212529 !default;
$colorPickerHandleColor: $shade000 !default;

//togglebutton
$toggleButtonBg: #6c757d !default;
$toggleButtonBorder: 1px solid #6c757d !default;
$toggleButtonTextColor: #ffffff !default;
$toggleButtonIconColor: #ffffff !default;
$toggleButtonHoverBg: #5a6268 !default;
$toggleButtonHoverBorderColor: #545b62 !default;
$toggleButtonTextHoverColor: #ffffff !default;
$toggleButtonIconHoverColor: #ffffff !default;
$toggleButtonActiveBg: #545b62 !default;
$toggleButtonActiveBorderColor: #4e555b !default;
$toggleButtonTextActiveColor: #ffffff !default;
$toggleButtonIconActiveColor: #ffffff !default;
$toggleButtonActiveHoverBg: #545b62 !default;
$toggleButtonActiveHoverBorderColor: #4e555b !default;
$toggleButtonTextActiveHoverColor: #ffffff !default;
$toggleButtonIconActiveHoverColor: #ffffff !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: $shade200 !default;
$inplaceTextHoverColor: $shade900 !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: #dc3545 !default;
$ratingCancelIconHoverColor: #dc3545 !default;
$ratingStarIconOffColor: $shade700 !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: $shade200 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: .286rem !default;
$sliderVerticalWidth: 0.286rem !default;
$sliderHandleWidth: 1.143rem !default;
$sliderHandleHeight: 1.143rem !default;
$sliderHandleBg: $primaryColor !default;
$sliderHandleBorder: 2px solid $primaryColor !default;
$sliderHandleBorderRadius: $borderRadius !default;
$sliderHandleHoverBorderColor: $primaryDarkColor !default;
$sliderHandleHoverBg: $primaryDarkColor !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: .5rem 0 !default;
$calendarPadding: 0 !default;
$calendarBg: $shade000 !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $shade900 !default;
$calendarBorder: $inputListBorder !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: .5rem !default;
$calendarHeaderBg: $shade100 !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: 1px solid $shade300 !default;
$calendarHeaderTextColor: $shade900 !default;
$calendarHeaderFontWeight: 600 !default;
$calendarHeaderCellPadding: .5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: .5rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: $borderRadius !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: $shade200 !default;
$calendarCellDateTodayBg: $shade400 !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $shade900 !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: .5rem !default;
$calendarTimePickerElementPadding: 0 .5rem !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//input switch
$inputSwitchWidth: 3rem !default;
$inputSwitchHeight: 1.75rem !default;
$inputSwitchBorderRadius: $borderRadius !default;
$inputSwitchHandleWidth: 1.250rem !default;
$inputSwitchHandleHeight: 1.250rem !default;
$inputSwitchHandleBorderRadius: $borderRadius !default;
$inputSwitchSliderPadding: .25rem !default;
$inputSwitchSliderOffBg: $shade400 !default;
$inputSwitchHandleOffBg: $shade000 !default;
$inputSwitchSliderOffHoverBg: $shade400 !default;
$inputSwitchSliderOnBg: $primaryColor !default;
$inputSwitchSliderOnHoverBg: $primaryColor !default;
$inputSwitchHandleOnBg: $shade000 !default;

//panel
$panelHeaderBorderColor: $shade300 !default;
$panelHeaderBorder: 1px solid $shade300 !default;
$panelHeaderBg: $shade100 !default;
$panelHeaderTextColor: $shade900 !default;
$panelHeaderFontWeight: 600 !default;
$panelHeaderPadding: 1rem 1.25rem !default;
$panelToggleableHeaderPadding: .5rem 1.25rem !default;

$panelHeaderHoverBg: $shade200 !default;
$panelHeaderHoverBorderColor: $shade300 !default;
$panelHeaderTextHoverColor: $shade900 !default;

$panelContentBorderColor: $shade300 !default;
$panelContentBorder: 1px solid $shade300 !default;
$panelContentBg: $shade200 !default;
$panelContentEvenRowBg: rgba(0, 0, 0, .05) !default;
$panelContentTextColor: $shade900 !default;
$panelContentPadding: 0 !default;

$panelFooterBorder: 1px solid $shade300 !default;
$panelFooterBg: $shade000 !default;
$panelFooterTextColor: $shade900 !default;
$panelFooterPadding: .5rem 1.25rem !default;

//accordion
$accordionSpacing: 0 !default;
$accordionHeaderBorder: $panelHeaderBorder !default;
$accordionHeaderBg: $panelHeaderBg !default;
$accordionHeaderTextColor: $panelHeaderTextColor !default;
$accordionHeaderFontWeight: $panelHeaderFontWeight !default;
$accordionHeaderPadding: $panelHeaderPadding !default;

$accordionHeaderHoverBg: $shade200 !default;
$accordionHeaderHoverBorderColor: $shade300 !default;
$accordionHeaderTextHoverColor: $shade900 !default;

$accordionHeaderActiveBg: $panelHeaderBg !default;
$accordionHeaderActiveBorderColor: $shade300 !default;
$accordionHeaderTextActiveColor: $shade900 !default;

$accordionHeaderActiveHoverBg: $shade200 !default;
$accordionHeaderActiveHoverBorderColor: $shade300 !default;
$accordionHeaderTextActiveHoverColor: $shade900 !default;

$accordionContentBorder: $panelContentBorder !default;
$accordionContentBg: $panelContentBg !default;
$accordionContentTextColor: $panelContentTextColor !default;
$accordionContentPadding: $panelContentPadding !default;

//tabview
$tabviewNavBorder: 1px solid #dee2e6 !default;
$tabviewNavBorderWidth: 0 0 1px 0 !default;
$tabviewNavBg: transparent !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: solid !default;
$tabviewHeaderBorderWidth: 1px !default;
$tabviewHeaderBorderColor: $shade000 $shade000 #dee2e6 $shade000 !default;
$tabviewHeaderBg: $shade000 !default;
$tabviewHeaderTextColor: $shade600 !default;
$tabviewHeaderFontWeight: $panelHeaderFontWeight !default;
$tabviewHeaderPadding: .75rem 1rem !default;
$tabviewHeaderMargin: 0 0 -1px 0 !default;

$tabviewHeaderHoverBg: $shade000 !default;
$tabviewHeaderHoverBorderColor: #dee2e6 !default;
$tabviewHeaderTextHoverColor: $shade600 !default;

$tabviewHeaderActiveBg: $shade000 !default;
$tabviewHeaderActiveBorderColor: #dee2e6 #dee2e6 $shade000 #dee2e6 !default;
$tabviewHeaderTextActiveColor: $shade700 !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: $panelContentBg !default;
$tabviewContentTextColor: $panelContentTextColor !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: .25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: $shade100 !default;

//card
$cardBodyPadding: 1.5rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $shade600 !default;
$cardContentPadding: 1rem 0 !default;
$cardFooterPadding: 1rem 0 0 0 !default;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, .2),
    0 1px 1px 0 rgba(0, 0, 0, .14),
    0 1px 3px 0 rgba(0, 0, 0, .12) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: $shade000 !default;
$paginatorTextColor: $primaryColor !default;
$paginatorBorder: solid $shade300 !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: .75rem !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: $shade000 !default;
$paginatorElementBorder: 1px solid #dee2e6 !default;
$paginatorElementIconColor: $primaryColor !default;
$paginatorElementHoverBg: $shade200 !default;
$paginatorElementHoverBorderColor: #dee2e6 !default;
$paginatorElementIconHoverColor: $primaryColor !default;
$paginatorElementBorderRadius: 0 !default;
$paginatorElementMargin: 0 0 0 -1px !default;
$paginatorElementPadding: 0 !default;

//table
$tableHeaderBorder: solid #dee2e6 !default;
$tableHeaderBorderWidth: 1px 0 0 0 !default;
$tableHeaderBg: $shade100 !default;
$tableHeaderTextColor: $shade900 !default;
$tableHeaderFontWeight: 600 !default;
$tableHeaderPadding: 1rem 1rem !default;

$tableHeaderCellPadding: 1rem 1rem !default;
$tableHeaderCellBg: $shade000 !default;
$tableHeaderCellTextColor: $shade900 !default;
$tableHeaderCellFontWeight: 600 !default;
$tableHeaderCellBorder: 1px solid #dee2e6 !default;
$tableHeaderCellBorderWidth: 1px 0 2px 0 !default;
$tableHeaderCellHoverBg: $shade200 !default;
$tableHeaderCellTextHoverColor: $shade900 !default;
$tableHeaderCellIconColor: $shade600 !default;
$tableHeaderCellIconHoverColor: $shade600 !default;
$tableHeaderCellHighlightBg: $shade000 !default;
$tableHeaderCellHighlightTextColor: $primaryColor !default;
$tableHeaderCellHighlightHoverBg: $shade200 !default;
$tableHeaderCellHighlightTextHoverColor: $primaryColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: $shade000 !default;
$tableBodyRowTextColor: $shade900 !default;
$tableBodyRowEvenBg: rgba(0, 0, 0, .05) !default;
$tableBodyRowHoverBg: $shade200 !default;
$tableBodyRowTextHoverColor: $shade900 !default;
$tableBodyCellBorder: 1px solid #dee2e6 !default;
$tableBodyCellBorderWidth: 1px 0 0 0 !default;
$tableBodyCellPadding: 1rem 1rem !default;

$tableFooterCellPadding: 1rem 1rem !default;
$tableFooterCellBg: $shade000 !default;
$tableFooterCellTextColor: $shade900 !default;
$tableFooterCellFontWeight: 600 !default;
$tableFooterCellBorder: 1px solid #dee2e6 !default;
$tableFooterCellBorderWidth: 1px 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: 1px solid #dee2e6 !default;
$tableFooterBorderWidth: 1px 0 1px 0 !default;
$tableFooterBg: $shade100 !default;
$tableFooterTextColor: $shade900 !default;
$tableFooterFontWeight: 600 !default;
$tableFooterPadding: 1rem 1rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 1px 0 0 0 !default;
$tableBottomPaginatorBorderWidth: 1px 0 0 0 !default;

$tableScaleSM: 0.5 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;
$dataViewListItemBorder: 1px solid $shade300 !default;
$dataViewListItemBorderWidth: 1px 0 0 0 !default;

//tree
$treeContainerPadding: 0.286rem !default;
$treeNodePadding: 0.143rem !default;
$treeNodeContentPadding: .286rem !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $shade600 !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 0 none !default;
$timelineEventMarkerBackground: $primaryColor !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: $shade300 !default;

//org chart
$organizationChartConnectorColor: $shade300 !default;

//message
$messageMargin: 1rem 0 !default;
$messagePadding: 1rem 1.25rem !default;
$messageBorderWidth: 1px !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 500 !default;

//inline message
$inlineMessagePadding: $inputPadding !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 0px !default;

//toast
$toastIconFontSize: 2rem !default;
$toastMessageTextMargin: 0 0 0 1rem !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 0 !default;
$toastShadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .1) !default;
$toastOpacity: 1 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: $inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: #cce5ff !default;
$infoMessageBorder: solid #b8daff !default;
$infoMessageTextColor: #004085 !default;
$infoMessageIconColor: #004085 !default;
$successMessageBg: #d4edda !default;
$successMessageBorder: solid #c3e6cb !default;
$successMessageTextColor: #155724 !default;
$successMessageIconColor: #155724 !default;
$warningMessageBg: #fff3cd !default;
$warningMessageBorder: solid #ffeeba !default;
$warningMessageTextColor: #856404 !default;
$warningMessageIconColor: #856404 !default;
$errorMessageBg: #f8d7da !default;
$errorMessageBorder: solid #f5c6cb !default;
$errorMessageTextColor: #721c24 !default;
$errorMessageIconColor: #721c24 !default;

//overlays
$overlayContentBorder: 1px solid rgba(0, 0, 0, .2) !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: none !default;

//dialog
$dialogHeaderBg: $shade000 !default;
$dialogHeaderBorder: 1px solid $shade200 !default;
$dialogHeaderTextColor: $shade900 !default;
$dialogHeaderFontWeight: 600 !default;
$dialogHeaderFontSize: 1.25rem !default;
$dialogHeaderPadding: 1rem !default;
$dialogContentPadding: 0 !default;
$dialogFooterBorder: 1px solid $shade200 !default;
$dialogFooterPadding: 1rem !default;

//confirmpopup
$confirmPopupContentPadding: $panelContentPadding !default;
$confirmPopupFooterPadding: 0 1.25rem 1.25rem 1.25rem !default;

//tooltip
$tooltipBg: $shade900 !default;
$tooltipTextColor: $shade000 !default;
$tooltipPadding: $inputPadding !default;

//steps
$stepsItemBg: white !default;
$stepsItemBorder: 1px solid $shade300 !default;
$stepsItemTextColor: $shade600 !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $shade900 !default;
$stepsItemNumberBorderRadius: $borderRadius !default;
$stepsItemActiveFontWeight: 600 !default;

//progressbar
$progressBarHeight: 1.5rem !default;
$progressBarBorder: 0 none !default;
$progressBarBg: $shade200 !default;
$progressBarValueBg: $primaryColor !default;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: $shade000 !default;
$menuBorder: 1px solid $shade300 !default;
$menuTextColor: $shade900 !default;
$menuitemPadding: .75rem 1rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $shade900 !default;
$menuitemIconColor: $shade900 !default;
$menuitemTextHoverColor: $shade900 !default;
$menuitemIconHoverColor: $shade900 !default;
$menuitemHoverBg: rgb(220, 220, 220) !default;
$menuitemTextFocusColor: $shade900 !default;
$menuitemIconFocusColor: $shade900 !default;
$menuitemFocusBg: transparent !default;
$menuitemTextActiveColor: $shade900 !default;
$menuitemIconActiveColor: $shade900 !default;
$menuitemActiveBg: transparent !default;
$menuitemActiveFocusBg: transparent !default;
$menuitemSubmenuIconFontSize: .875rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: .75rem 1rem !default;
$submenuHeaderBg: $shade000 !default;
$submenuHeaderTextColor: $shade900 !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: 600 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 1px solid rgba(0, 0, 0, .15) !default;
$overlayMenuShadow: none !default;
$verticalMenuPadding: .5rem 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: .5rem 0 !default;

$breadcrumbPadding: 1rem !default;
$breadcrumbBg: $shade100 !default;
$breadcrumbBorder: 0 none !default;
$breadcrumbItemTextColor: $primaryColor !default;
$breadcrumbItemIconColor: $primaryColor !default;
$breadcrumbLastItemTextColor: $shade600 !default;
$breadcrumbLastItemIconColor: $shade600 !default;
$breadcrumbSeparatorColor: $shade600 !default;

$horizontalMenuPadding: .5rem 1rem !default;
$horizontalMenuBg: none !default;
$horizontalMenuBorder: 0 none !default;
$horizontalMenuTextColor: rgba(0, 0, 0, .9) !default;
$horizontalMenuRootMenuitemPadding: 1rem !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: rgba(0, 0, 0, .5) !default;
$horizontalMenuRootMenuitemIconColor: rgba(0, 0, 0, .5) !default;
$horizontalMenuRootMenuitemTextHoverColor: rgba(0, 0, 0, .7) !default;
$horizontalMenuRootMenuitemIconHoverColor: rgba(0, 0, 0, .7) !default;
$horizontalMenuRootMenuitemHoverBg: transparent !default;
$horizontalMenuRootMenuitemTextActiveColor: rgba(0, 0, 0, .9) !default;
$horizontalMenuRootMenuitemIconActiveColor: rgba(0, 0, 0, .9) !default;
$horizontalMenuRootMenuitemActiveBg: transparent !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: .75rem !default;

$tagPadding: .25rem .4rem !default;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: $shade200 !default;
$carouselIndicatorHoverBg: $shade300 !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: .5rem !default;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9) !default;
$galleriaCloseIconMargin: .5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: $shade100 !default;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaCloseIconHoverColor: $shade100 !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: $borderRadius !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: $shade100 !default;
$galleriaItemNavigatorMargin: 0 .5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaItemNavigatorHoverColor: $shade100 !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: $borderRadius !default;

$galleriaCaptionBg: rgba(0, 0, 0, .5) !default;
$galleriaCaptionTextColor: $shade100 !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: $shade200 !default;
$galleriaIndicatorHoverBg: $shade300 !default;
$galleriaIndicatorBorderRadius: $borderRadius !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, .5) !default;
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, .4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, .6) !default;

$galleriaThumbnailContainerBg: rgba(0, 0, 0, .9) !default;
$galleriaThumbnailContainerPadding: 1rem .25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: $shade100 !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaThumbnailNavigatorHoverColor: $shade100 !default;
$galleriaThumbnailNavigatorBorderRadius: $borderRadius !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1rem 0 !default;
$dividerHorizontalPadding: 0 1rem !default;
$dividerVerticalMargin: 0 1rem !default;
$dividerVerticalPadding: 1rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: $shade300 !default;

//avatar
$avatarBg: $shade300 !default;
$avatarTextColor: $textColor !default;

//chip
$chipBg: $shade300 !default;
$chipTextColor: $textColor !default;
$chipBorderRadius: 16px !default;
$chipFocusBg: $shade400 !default;
$chipFocusTextColor: $textColor !default;

//scrollTop
$scrollTopBg: rgba(0, 0, 0, 0.7) !default;
$scrollTopHoverBg: rgba(0, 0, 0, 0.8) !default;
$scrollTopWidth: 3rem !default;
$scrollTopHeight: 3rem !default;
$scrollTopBorderRadius: $borderRadius !default;
$scrollTopFontSize: 1.5rem !default;
$scrollTopTextColor: $shade100 !default;

//skeleton
$skeletonBg: $shade200 !default;
$skeletonAnimationBg: rgba(255, 255, 255, 0.4) !default;

//splitter
$splitterGutterBg: $shade100 !default;
$splitterGutterHandleBg: $shade300 !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 1.3rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $shade700 !default;
$speedDialActionHoverBg: $shade800 !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: .5rem !default;
$dockItemBorderRadius: $borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, .1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: .5rem .5rem !default;
$dockBorderRadius: .5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

:root {
    --font-family: #{$fontFamily};
    --surface-a:#{$shade000};
    --surface-b:#{$shade100};
    --surface-c:#{$shade200};
    --surface-d:#{$shade300};
    --surface-e:#{$shade000};
    --surface-f:#{$shade000};
    --text-color:#{$shade900};
    --text-color-secondary:#{$shade600};
    --primary-color:#{$primaryColor};
    --primary-color-text:#{$primaryTextColor};
    --primary-light-color:#{primaryLightColor};
    --surface-0: #ffffff;
    --surface-50: #f9fafb;
    --surface-100: #f8f9fa;
    --surface-200: #F9F7FA;
    --surface-300: #dee2e6;
    --surface-400: #ced4da;
    --surface-500: #adb5bd;
    --surface-600: #6c757d;
    --surface-700: #495057;
    --surface-800: #343a40;
    --surface-900: #212529;
    --gray-50: #f9fafb;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground: #efefef;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
    --apyre-blue: #{$apyreBlue};
    --apyre-sky-blue: #{$apyreSkyBlue};
    --apyre-app-background: #{$apyreAppBackground};
    --apyre-border-1: #{$apyreBorder1};
    --primary-darker-color: #{$primaryDarkerColor};
}
$primaryLightColor: #a855f775 !default;
$primaryColor: #A24399 !default;
$primaryDarkColor: #692B63 !default;
$primaryDarkerColor: #493146 !default;
$primaryTextColor: #FFFFFF !default;
$primaryAccentBg: #EBE6F0 !default;
$highlightBg: $primaryColor !default;
$highlightTextColor: $primaryTextColor !default;
$highlightFocusBg: darken($primaryColor, 8%) !default;

@import '../_variables_light';
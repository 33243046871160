@import "primeicons/primeicons.css";

* {
    outline: none;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    font-size: 16px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: #C8DFF6;
}

a,
a:active,
a:visited,
a:link,
a:hover {
    text-decoration: none;
    ;
}

button {
    border: none;
    cursor: pointer;
}

.no-break-inside {
    break-inside: avoid-column;
}


.fb-incoming {
    input {
        background: var(--yellow-300);
    }

    .p-dropdown {
        background: var(--yellow-300);
    }
}

.prevent-click {
    pointer-events: none;
}

.map-container {
    width: 100%;
    height: 100%;
}

.case-search-listing {

    em {
        font-style: normal !important;
        font-weight: inherit !important;
        background: rgb(255, 221, 0);
        border-radius: 3px;
    }
}


.dispatch-button {
    background-color: var(--apyre-blue);
    font-size: 1.5rem;
    padding: 1rem;

    &:hover {
        background-color: darken(#0072BC, 10);
    }
}

.p-menu.email-menu {
    width: 300px;
}

.apyre-dialog-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
    padding: .5em;
    border-radius: 0 0 6px 6px;
    border: 2px solid var(--bluegray-bluegray-100, #DADEE3);
    background: var(--primaryAccentBg, #EBE6F0);
    overflow: hidden;

    h2 {
        flex: 1 1 auto;
        font-size: 1.25em;
        padding: 0;
        margin: 0;
    }
}

.apyre-dialog-content {
    width: 100%;
    padding: 1rem;
}

.apyre-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    width: 100%;
    padding: .5rem 1rem;
    gap: .5rem;
    border-top: 1px solid var(--bluegray-600);
    overflow: hidden;
}


.apyre-blue-bg {
    background-color: var(--apyre-blue);
}

.apyre-blue {
    color: var(--apyre-blue);
}


apyre-document-line:last-child {
    .document-line {
        border: none !important;
    }
}

.sq-card-wrapper.sq-error .sq-card-iframe-container::before {
    border: none !important;
}

.dispatch-backdrop.p-dialog-mask.p-component-overlay {
    background: rgba(61, 0, 56, 0.8) !important;
}


.stat-toolbar {
    .p-dropdown {

        .p-dropdown-label {
            font-size: 11px
        }
    }

    .p-button {
        padding: .3rem .5rem;

        .p-button-label {
            font-size: 11px;
        }
    }
}

.icon-inquiry {
    color: gray;
}

.icon-engaged {
    color: #A24399;
}

.icon-provisioned {
    color: #0072BC
}

.icon-dispatched {
    color: #0072BC;
}

.edit-obit-button {
    .p-button-label {
        white-space: nowrap;
    }
}

.cropper-container {
    .ngx-ic-overlay {
        outline: none !important
    }

    .ngx-ic-cropper {
        outline: rgba(255, 255, 255, .8) solid 100vw !important;
        color: black;
    }
}